<template>
    <div>
        <div>Study Mapping</div>
        <Tags></Tags>
        <kendo-datasource
            :ref="'studyMappingSelectorDataSource'"
            :data="selector">
        </kendo-datasource>
        <kendo-datasource
            :ref="'filterSelectorDataSource'"
            :data="filterStudySelector">
        </kendo-datasource>
        <kendo-datasource
            :ref="'sortSelectorDataSource'"
            :data="sortStudySelector">
        </kendo-datasource>
        <div style="display: flex; flex-direction:row; border: 0px solid black; justify-content: flex-start; height:25px;">
            <div>Grouped by:&nbsp;</div>
            <kendo-dropdownlist
              class="StudyMappingDropdown"
              :id="'studyMappingSelector'"
              :data-source-ref="'studyMappingSelectorDataSource'"
              :data-value-field="'SelectorIdentity'"
              :data-text-field="'SelectorName'"
              :change="changeSelector"
              >
            </kendo-dropdownlist>
            <div>Filter by:&nbsp;</div>
            <kendo-dropdownlist
              class="StudyMappingDropdown"
              style="width:900px;"
              :id="'filterSelector'"
              :data-source-ref="'filterSelectorDataSource'"
              :data-value-field="'SelectorIdentity'"
              :data-text-field="'SelectorName'"
              >
            </kendo-dropdownlist>
            <div>Sort by:&nbsp;</div>
            <kendo-dropdownlist
              class="StudyMappingDropdown"
              :id="'sortSelector'"
              :data-source-ref="'sortSelectorDataSource'"
              :data-value-field="'SelectorIdentity'"
              :data-text-field="'SelectorName'"
              >
            </kendo-dropdownlist>
        </div>
        <div v-if="visible0">
          Table below will be organized by one row per Study with all mapped Citations in front
          <div style='width: 100%; display: flex; justify-content: flex-start;' class='k-grid-toolbar' id="myToolbar">
            <kendo-button id="studyAll" style="height: 25px" @click='openReviewByStudies()'>Export To Excel</kendo-button>
            <kendo-button id="studyAll" style="height: 25px" @click='openReviewByStudies()'>Add Study</kendo-button>
            <kendo-button id="studyAll" style="height: 25px" @click='openReviewByStudies()'>Edit Study</kendo-button>
            <kendo-button id="studyAll" style="height: 25px" @click='openReviewByStudies()'>Extract All studies</kendo-button>
            <kendo-button id="studyReview" style="height: 25px" @click='openReviewByStudies()'>Extract Marked Studies</kendo-button>
            <kendo-button id="studyClear" style="height: 25px" tabindex="0" disabled>Clear Marked Studies</kendo-button>
          </div>
          <kendo-grid id="study-mapping-by-studies-grid-b"
            :ref="'studyListGrid'"
            style="study-list-grid"
            :data-source="studyMappingByStudiesDataSource"
            :columns="columnsStudies"
            :selectable="'multiple row'"
            :sortable="sortable"
            :pageable="pageable"
            :groupable="groupable"
            :excel="excel"
            :scrollable="scrollable"
            :filterable="filterable">
            <kendo-grid-column field="StudyID" title="Study ID"></kendo-grid-column>
          </kendo-grid>
        </div>
        <div v-if="visible1">
          <div style='width: 100%; display: flex; justify-content: flex-start;' class='k-grid-toolbar' id="myToolbar">
            <button id="studyAll" style='height: 25px, padding-left: 20px; padding-right: 10px;' @click='openReviewByCitations()'><u>E</u>xport To Excel</button>
            <button id="studyAll" style='height: 25px, padding-left: 20px; padding-right: 10px;' @click='openReviewByCitations()'>Extract <u>A</u>ll Citations</button>
            <button id="studyReview" style="height: 25px" @click='openReviewByCitations()'>Extract <u>M</u>arked Citations</button>
            <button id="studyClear" style="height: 25px" tabindex="0" disabled><u>C</u>lear Marked Citations</button>
          </div>
          <kendo-grid id="study-mapping-by-citations-grid"
            :ref="'citationListGrid'"
            style="citation-list-grid"
            :data-source="studyMappingByCitationsDataSource"
            :columns="columnsCitations"
            :selectable="'multiple row'"
            :sortable="sortable"
            :pageable="pageable"
            :groupable="groupable"
            :excel="excel"
            :scrollable="scrollable"
            :filterable="filterable"
            :change="modifyStudy">
          </kendo-grid>
        </div>
        <b-button-group class="action-buttons-nav">
            &nbsp;
            <b-button type="submit" variant="primary" size="sm" style="width: 150px">Print/Export to Excel</b-button>
        </b-button-group>
        <kendo-window :ref="'detachWindow'"
                      :width="windowWidth"
                      :title="windowTitle"
                      :visible="windowVisible"
                      :modal="true"
                      :top="'0px'">
            <component class="component" :is="component"></component>
        </kendo-window>
    </div>
</template>

<script>

import StudyMappingByStudiesDataSource from '@/assets/data/StudyMappingByStudies.json'
import StudyMappingByCitationsDataSource from '@/assets/data/StudyMappingByCitations.json'
import SelectCitation from '@/views/secure/manage/templates/SelectCitationBV1.vue'
import SelectStudy from '@/views/secure/manage/templates/SelectStudyV2.vue'
import AddNewStudy from '@/views/secure/manage/templates/AddNewStudyV2.vue'
import Tags from '@/assets/data/Tags.json'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'validation-home',
  components: {
    SelectCitation,
    SelectStudy,
    AddNewStudy,
    Tags
  },
  data () {
    return {
      windowTitle: '',
      windowWidth: '',
      windowVisible: false,
      component: '',
      visible0: true,
      visible1: false,
      visible2: false,
      expandField: true,
      checkboxes: true,
      excel: { fileName: 'Kendo UI Grid Export.xlsx', allPages: true },
      selector: [
        { SelectorIdentity: 2, SelectorName: 'Studies' },
        { SelectorIdentity: 3, SelectorName: 'Citations' }
      ],
      filterStudySelector: [
        { SelectorIdentity: 1, SelectorName: 'All' },
        { SelectorIdentity: 2, SelectorName: 'With NO citations' },
        { SelectorIdentity: 3, SelectorName: 'With Subsequent but NO MAIN citations' }
      ],
      sortStudySelector: [
        { SelectorIdentity: 3, SelectorName: 'Number of citations Low to High' },
        { SelectorIdentity: 4, SelectorName: 'Number of Citations High to Low' }
      ],
      filterCitationSelector: [
        { SelectorIdentity: 1, SelectorName: 'All' },
        { SelectorIdentity: 2, SelectorName: 'With NO studies' },
        { SelectorIdentity: 3, SelectorName: 'Fully Mapped Citations' },
        { SelectorIdentity: 3, SelectorName: 'NOT Fully Mapped Citations' },
        { SelectorIdentity: 3, SelectorName: 'Citation IS MAIN of at least one study' },
        { SelectorIdentity: 3, SelectorName: 'Citation IS MAIN of at least one study' },
        { SelectorIdentity: 3, SelectorName: 'Citation IS NOT MAIN of any study' }
      ],
      sortCitationSelector: [
        { SelectorIdentity: 1, SelectorName: 'Low to High Number of mapped studies' },
        { SelectorIdentity: 2, SelectorName: 'High to Low Number of mapped studies' }
      ],
      studiesSortSelector: [
        { SelectorIdentity: 1, SelectorName: 'Is Main - High to low' },
        { SelectorIdentity: 2, SelectorName: 'Is Main - Low to high' },
        { SelectorIdentity: 3, SelectorName: 'Study ID - High to low' },
        { SelectorIdentity: 4, SelectorName: 'Study ID - Low to high' },
        { SelectorIdentity: 5, SelectorName: 'Study Acronym - High to low' },
        { SelectorIdentity: 6, SelectorName: 'Study Acronym - Low to high' }
      ],
      columnsStudies: [
        {
          title: 'Study',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'StudyID', title: 'Registry #', width: '50px', headerAttributes: { class: 'grid-header-dark' }, filterable: true },
            { field: 'StudyAcronym', title: 'Study ID', width: '50px', headerAttributes: { class: 'grid-header-dark' }, filterable: true },
            { field: 'StudyID', title: 'Tags', width: '50px', headerAttributes: { class: 'grid-header-dark' }, template: '<button>Tag1</button><button>Tag2</button>', filterable: true }
          ]
        },
        {
          title: 'Map<br>Citation(s)',
          command: [{
            name: 'Map',
            click: this.addNewCitation
          }],
          width: '30px',
          headerAttributes: { class: 'grid-header-light' }
        },
        {
          field: 'MainPub',
          title: 'Main Citation',
          width: '75px',
          headerAttributes: { class: 'grid-header-light' },
          attributes: { class: '# if(MainPub.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
          template: '#=MainPub.replace("--Ex--", "")#',
          filterable: true
        },
        {
          title: 'Subsequent Citations',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            {
              field: 'SubsequentPub01',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub01.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub01.replace("--Ex--", "")#',
              filterable: false
            },
            {
              field: 'SubsequentPub02',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub02.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub02.replace("--Ex--", "")#',
              filterable: false
            },
            {
              field: 'SubsequentPub03',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub03.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub03.replace("--Ex--", "")#',
              filterable: false
            },
            {
              field: 'SubsequentPub04',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if (SubsequentPub04.includes("--Fde--")) { # flagForDe # } else if (SubsequentPub04.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub04.replace("--Ex--", "").replace("--Fde--", "")#',
              filterable: false
            }
          ]
        }
      ],
      columnsStudiesB: [
        {
          title: 'Study',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'StudyID', title: 'Registry #', width: '50px', headerAttributes: { class: 'grid-header-dark' }, filterable: true },
            { field: 'StudyAcronym', title: 'Study Acronym', width: '50px', headerAttributes: { class: 'grid-header-dark' }, filterable: true }
          ]
        },
        {
          title: 'Map<br>Citation(s)',
          command: [{
            name: 'Edit',
            click: this.addNewCitationB
          }],
          width: '30px',
          headerAttributes: { class: 'grid-header-light' }
        },
        {
          field: 'MainPub',
          title: 'Main Citation',
          width: '75px',
          headerAttributes: { class: 'grid-header-light' },
          attributes: { class: '# if(MainPub.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
          template: '#=MainPub.replace("--Ex--", "")#',
          filterable: true
        },
        {
          title: 'Subsequent Citations',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            {
              field: 'SubsequentPub01',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub01.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub01.replace("--Ex--", "")#',
              filterable: false
            },
            {
              field: 'SubsequentPub02',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub02.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub02.replace("--Ex--", "")#',
              filterable: false
            },
            {
              field: 'SubsequentPub03',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub03.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub03.replace("--Ex--", "").replace("--Fde--", "")#',
              filterable: false
            },
            {
              field: 'SubsequentPub04',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub04.includes("--Fde--")) { # flagForDe # }  else { # defaultCitation # } #' },
              template: '#=SubsequentPub04.replace("--Fde--", "")#',
              filterable: false
            }
          ]
        }
      ],
      columnsCitations: [
        {
          title: 'Citations',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'CitationID', title: 'Citation ID', width: '50px', headerAttributes: { class: 'grid-header-light' }, filterable: true, attributes: { class: '# if(CitationID.includes("1")) { # flagForDe # } else if (CitationID.includes("3")) { # excludedCitation # } else { # defaultCitation # } #' } },
            { field: 'Author', title: 'Author', width: '60px', headerAttributes: { class: 'grid-header-light' }, filterable: true, attributes: { class: '# if(CitationID.includes("1")) { # flagForDe # } else if (CitationID.includes("3")) { # excludedCitation # } else { # defaultCitation # } #' } },
            { field: 'Year', title: 'Year', width: '60px', headerAttributes: { class: 'grid-header-light' }, filterable: true, attributes: { class: '# if(CitationID.includes("1")) { # flagForDe # } else if (CitationID.includes("3")) { # excludedCitation # } else { # defaultCitation # } #' } },
            { field: 'Title', title: 'Title', width: '80px', headerAttributes: { class: 'grid-header-light' }, filterable: true, attributes: { class: '# if(CitationID.includes("1")) { # flagForDe # } else if (CitationID.includes("3")) { # excludedCitation # } else { # defaultCitation # } #' } },
            { field: 'CurrentStage', title: 'Current Stage', width: '80px', headerAttributes: { class: 'grid-header-light' }, filterable: true, attributes: { class: '# if(CitationID.includes("1")) { # flagForDe # } else if (CitationID.includes("3")) { # excludedCitation # } else { # defaultCitation # } #' } },
            { field: 'CurrentStage', title: 'Fully Mapped', template: '<input type="checkbox">', width: '80px', headerAttributes: { class: 'grid-header-light' }, filterable: true, attributes: { class: '# if(CitationID.includes("1")) { # flagForDe # } else if (CitationID.includes("3")) { # excludedCitation # } else { # defaultCitation # } #' } },
            { field: 'CitationID', title: 'Tags', width: '50px', headerAttributes: { class: 'grid-header-light' }, template: '<button>Tag1</button><button>Tag2</button>', filterable: true }
          ]
        },
        {
          title: 'Map<br>Study(s)',
          command: [{
            name: 'Map',
            click: this.linkStudy
          }],
          width: '40px',
          headerAttributes: { class: 'grid-header-dark' }
        },
        {
          title: 'Studies',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'SubsequentStudy01', title: 'Study', width: '75px', headerAttributes: { class: 'grid-header-dark' }, filterable: false },
            { field: 'SubsequentStudy02', title: 'Study', width: '75px', headerAttributes: { class: 'grid-header-dark' }, filterable: false },
            { field: 'SubsequentStudy03', title: 'Study', width: '75px', headerAttributes: { class: 'grid-header-dark' }, filterable: false },
            { field: 'SubsequentStudy04', title: 'Study', width: '75px', headerAttributes: { class: 'grid-header-dark' }, filterable: false }
          ]
        }
      ],
      pageable: {
        refresh: true,
        pageSizes: true,
        buttonCount: 2,
        pageSize: 5
      },
      groupable: false,
      selectable: 'cell',
      sortable: true,
      scrollable: true,
      filterable: true,
      studyMappingByStudiesDataSource: StudyMappingByStudiesDataSource,
      studyMappingByCitationsDataSource: StudyMappingByCitationsDataSource
    }
  },
  methods: {
    openReviewByCitations: function () {
      this.$router.push({ path: '/secure/StudyMappingIndividualReviewByCitations' })
    },
    openReviewByStudies: function () {
      this.$router.push({ path: '/secure/StudyMappingIndividualReviewByStudies' })
    },
    changeSelector: function (e) {
      var dsFilterWidget = this.$refs.filterSelectorDataSource.kendoWidget()
      var dsSortWidget = this.$refs.sortSelectorDataSource.kendoWidget()
      switch (e.sender.selectedIndex) {
        case 0:
          // Grouped by Studies
          dsFilterWidget.data(this.filterStudySelector)
          dsSortWidget.data(this.sortStudySelector)
          this.visible0 = true
          this.visible1 = false
          break
        case 1:
          // Grouped by Citation
          dsFilterWidget.data(this.filterCitationSelector)
          dsSortWidget.data(this.sortCitationSelector)
          this.visible0 = false
          this.visible1 = true
          break
      }
    },
    reportAction: function (action) {
      var windowWidget = this.$refs.detachWindow.kendoWidget()
      switch (action) {
        case 'SelectCitation':
          if (this.$store.state.citationChange === 1) {
            this.windowTitle = 'Add Citation(s)'
          } else {
            this.windowTitle = 'Edit mapped Citations'
          }
          this.component = 'SelectCitation'
          this.windowVisible = true
          windowWidget.close()
          windowWidget.width = 5200
          windowWidget.center().open()
          break
        case 'SelectCitationB':
          this.windowTitle = 'Edit Citations for this study'
          this.windowWidth = '1200px'
          this.component = 'SelectCitationB'
          this.windowVisible = true
          windowWidget.close()
          windowWidget.width = 5200
          windowWidget.center().open()
          break
        case 'AddNewStudy':
          this.windowTitle = 'New Study'
          this.component = 'AddNewStudy'
          this.windowVisible = true
          windowWidget.close()
          windowWidget.width = 800
          windowWidget.center().open()
          this.windowWidth = '800px'

          break
        case 'SelectStudy':
          this.windowTitle = 'Edit mapped Studies'
          this.component = 'SelectStudy'
          this.windowVisible = true
          windowWidget.close()
          windowWidget.width = 800
          windowWidget.center().open()
          break
      }
    },
    addNewCitation: function () {
      this.$store.state.citationChange = 2
      this.reportAction('SelectCitation')
    },
    addNewCitationB: function () {
      this.$store.state.citationChange = 1
      this.reportAction('SelectCitationB')
    },
    modifyCitation: function (e) {
      console.log(e)
      console.log(this)
      var gridWidget = this.$refs.studyListGrid.kendoWidget()
      this.$store.state.isMainCitationSelected = (gridWidget.select().closest('td').index() === 3)
      this.$store.state.citationChange = 2
      this.reportAction('SelectCitation')
    },
    addNewStudy: function () {
      alert(7)
      this.reportAction('AddNewStudy')
    },
    linkStudy: function () {
      this.reportAction('SelectStudy')
    }
  },
  created: function () {
    emitter.on('changeStudyWindowTitle', (title) => {
      this.windowTitle = title
    })
  }
}
</script>

<style>
  .excludedCitation {
    color: black;
  }

  .flagForDe {
    color: black;
  }

  .defaultCitation {
    color: white;
  }
  .k-grid .k-grid-content .k-state-selected {
    background-color: rgb(131, 176, 248) !important;
  }
  .k-dropdown .k-dropdown-wrap {
      width:100px !important;
  }
</style>
